/* merriweather-sans-300normal - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Merriweather Sans Light '),
    local('Merriweather Sans-Light'),
    url('./files/merriweather-sans-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-sans-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-sans-300italic - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Merriweather Sans Light italic'),
    local('Merriweather Sans-Lightitalic'),
    url('./files/merriweather-sans-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-sans-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-sans-400normal - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Merriweather Sans Regular '),
    local('Merriweather Sans-Regular'),
    url('./files/merriweather-sans-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-sans-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-sans-400italic - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Merriweather Sans Regular italic'),
    local('Merriweather Sans-Regularitalic'),
    url('./files/merriweather-sans-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-sans-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-sans-700normal - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Merriweather Sans Bold '),
    local('Merriweather Sans-Bold'),
    url('./files/merriweather-sans-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-sans-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-sans-700italic - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Merriweather Sans Bold italic'),
    local('Merriweather Sans-Bolditalic'),
    url('./files/merriweather-sans-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-sans-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-sans-800normal - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Merriweather Sans ExtraBold '),
    local('Merriweather Sans-ExtraBold'),
    url('./files/merriweather-sans-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-sans-latin-800.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-sans-800italic - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Merriweather Sans ExtraBold italic'),
    local('Merriweather Sans-ExtraBolditalic'),
    url('./files/merriweather-sans-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-sans-latin-800italic.woff') format('woff'); /* Modern Browsers */
}

